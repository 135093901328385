@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Lotto purchase success component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin lotto-purchase-success-style(
  $theme: theme('lotto-purchase-success', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)
) {
  fgb-lottos-success-page {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .lotto-purchase-success {
      background-color: $background-color;
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }

    .text-primary {
      color: get-color-by-background($background-color, white, $primary-color) !important;
    }

    p {
      color: get-color-by-background($background-color) !important;
    }

    .lotto-container {
      border-color: get-color-by-background($background-color, $background-color, #dedddd);
    }

    .confirm-btn {
      color: get-color-by-background($primary-color);
    }

    .wallet-btn {
      color: get-color-by-background($background-color, #ffffff, $primary-color);
    }

    hr {
      border-color: get-color-by-background($background-color, $primary-color, rgba(0, 0, 0, 0.125));
    }
  }
}
