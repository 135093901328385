@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Points summary component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin points-summary-style($theme: theme('points-summary', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgb-points-summary {
    .points-summary {
      color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
    }
  }

  fgb-points-summary-bar {
    .points-summary-bar {
      color: get-color-by-background($background-color, #ffffff, $primary-color);
    }

    .text-danger {
      color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
    }
  }
}
