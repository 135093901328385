@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Feature rewards component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin featured-rewards-style($theme: theme('featured-rewards', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgbcl-featured-rewards {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .featured-rewards-heading,
    .featured-rewards-show-all {
      color: get-color-by-background($background-color);
    }

    div.mt-4.mb-2 {
      margin-top: 0 !important;
    }

    @content;
  }
}
