@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Account details component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin account-details-style($theme: theme('account-details', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgb-account-details {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .details-card {
      background-color: get-color-by-background($background-color, var.$black, var.$white) !important;
      border-color: get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125)) !important;
    }

    .rounded-input {
      background-color: get-color-by-background($background-color, var.$black, #f7f7f7);
      border: 1px solid get-color-by-background($background-color, #163c26, #ffffff) !important;

      input,
      span {
        color: get-color-by-background($background-color) !important;
      }
    }

    @content;
  }
}
