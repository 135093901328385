@use 'club-overrides' as var;
@use 'partials' as *;

@mixin predictor-style($theme: theme('predictor', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgb-survey-item-predictor {
    .survey-info {
      background-color: get-color-by-background($background-color, #000000, #ffffff) !important;
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }

    .survey-predictor-answer,
    .image-container {
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }
  }
}
