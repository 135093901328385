@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Tier component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin tier-style($theme: theme('tier', var.$primary-color, #f0f4f3, #ffffff, var.$primary-color), $variables...) {
  $var: keywords($variables);
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;
  $border-color: get-value($tertiary-color, $var, 'border-color');

  fgb-tier {
    .tier {
      background-color: $background-color;
      color: get-color-by-background($background-color);
      border: 1px solid get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125));

      .full-name,
      .tier-points,
      .expiry-date {
        color: get-color-by-background($background-color, #ffffff, $primary-color);
      }

      .points-wrapper {
        background-color: $secondary-color;
        border: 1px solid $border-color;
      }

      .points-information:not(:last-of-type) {
        border-color: get-color-by-background($secondary-color) !important;
      }

      @content;
    }
  }

  // Modal styling
  .tier-modal-content,
  .tier-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tier-modal-content {
    margin: 15px 0;
  }

  .tier-modal-header {
    margin-bottom: 30px;
    border-bottom: 1px solid #dedddd;
    height: 35px;
    display: flex;
    align-items: center;

    h4 {
      font-size: 14px;
      text-transform: uppercase;
      color: get-color-by-background($background-color, #ffffff, $primary-color);
    }

    #tier-close-btn {
      margin-left: auto;
      color: #999999;
      margin-bottom: 0.5rem;
    }
  }

  .tier-modal {
    padding: 10px 15px;
  }

  .tier-modal-footer {
    h5 {
      font-size: 14px;
      font-family: var.$font-default;
      font-weight: bold !important;
      color: get-color-by-background($background-color, #ffffff, $primary-color);
    }
  }

  @media screen and (max-width: 991px) {
    .full-name {
      color: get-color-by-background($background-color, #ffffff, var.$black) !important;
    }
  }
}
