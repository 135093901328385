@use 'club-overrides' as var;
@use 'partials' as *;

@mixin pick-a-player-style($theme: theme('predictor', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;
  $mobile-border-color: var.$gold-color;

  fgb-fantasy-games-page,
  fgb-fantasy-leaderboard-page,
  fgb-fantasy-player-select-page {
    color: get-color-by-background($background-color) !important;

    .fantasy-panel {
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;

      .question-text {
        color: get-color-by-background($background-color) !important;
      }

      .game-summary {
        background-color: get-color-by-background($background-color, $tertiary-color, $primary-color) !important;
        border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
      }

      .fantasy-game-panel-right-div {
        background-color: get-color-by-background($background-color, #000000, #ffffff) !important;
      }

      @media screen and (max-width: 991px) {
        border-color: $mobile-border-color !important;

        .game-summary {
          background-color: #002919 !important;
          border-color: #163c26 !important;
        }

        .fantasy-game-panel-right-div {
          $bg-color: #000000;
          background-color: $bg-color !important;
          color: get-color-by-background($bg-color) !important;
          border-top: 1px solid $mobile-border-color !important;

          .question-text {
            color: get-color-by-background($bg-color) !important;
          }
        }
      }
    }

    .fantasy {
      p {
        margin-bottom: 0 !important;
      }
    }

    .player-card.selected {
      .player-name-div {
        background-color: get-color-by-background($background-color, var.$tertiary-color, var.$primary-color) !important;
      }
    }
    p {
      color: get-color-by-background($background-color) !important;
    }

    .card-body,
    .accordion-item {
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }

    .accordion-item {
      overflow: hidden;
    }

    .accordion-button {
      background-color: get-color-by-background($background-color, #002919, #ffffff) !important;
    }

    .accordion-body {
      background-color: get-color-by-background($background-color, #000000, #ffffff) !important;
    }

    .leaderboard-card {
      background-color: get-color-by-background($background-color, #000000, #ffffff) !important;
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
      overflow: hidden;

      .nav-item {
        background-color: inherit !important;
      }
    }

    .leaderboard-date-toggle {
      height: 27px !important;
      color: get-color-by-background($background-color) !important;
      background-color: get-color-by-background($background-color, #272727, #dedddd) !important;
    }

    .back-btn {
      color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
    }

    .pick-a-player-message {
      color: get-color-by-background($background-color) !important;
      background-color: get-color-by-background($background-color, #000000, #ffffff) !important;
      border-color: get-color-by-background($background-color, #163c26, var.$disabled-color) !important;
    }

    .owl-prev,
    .owl-next {
      margin: 3px !important;
      padding: 0 !important;
      color: get-color-by-background($background-color) !important;
    }

    fgb-fantasy-leaderboard,
    .col-name {
      color: get-color-by-background($background-color) !important;
    }

    fgb-fantasy-history-list {
      .card {
        color: get-color-by-background($background-color) !important;
        background-color: get-color-by-background($background-color, #000000, #ffffff) !important;
        border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
      }
    }
  }
}
