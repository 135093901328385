@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Home earn step component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin home-earn-steps-style($theme: theme('home-earn-steps', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgb-home-page,
  fgb-marketplace-page {
    fgbcl-card-link-earn-steps {
      $primary-color: map-get($theme, 'primary');
      $secondary-color: map-get($theme, 'secondary');
      $tertiary-color: map-get($theme, 'tertiary');
      $accent-color: map-get($theme, 'accent');
      $background-color: $tertiary-color;

      .card-link-earn-step-box {
        background-color: get-color-by-background($background-color, var.$black, #ffffff);
        margin-bottom: 10px;
        box-shadow: none !important;
        border: 1px solid get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125)) !important;
        padding-left: 15px;
        padding-right: 15px;

        hr {
          border-color: get-color-by-background($background-color, $primary-color, rgba(0, 0, 0, 0.125));
        }

        h5.card-title {
          text-align: left !important;
          font-size: 16px;
        }

        .card-link-earn-step-icon {
          width: 50px !important;
          box-shadow: none !important;
        }

        @for $i from 1 through 3 {
          .icon#{$i} {
            font-size: 25px !important;
            height: 50px !important;
            width: 50px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: none !important;
            border: 1px solid get-color-by-background($background-color, $primary-color, var.$disabled-color) !important;
            background-color: get-color-by-background($background-color, var.$black, #ffffff);
          }
        }

        .color-1 {
          color: $primary-color !important;
        }

        .color-2 {
          color: var.$tertiary-color !important;
        }

        .color-3 {
          color: get-color-by-background($background-color) !important;
        }

        .card-link-earn-step-number {
          height: 30px !important;
          width: 30px !important;
          left: -22px !important;
          border: 1px solid get-color-by-background($background-color, $primary-color, var.$disabled-color) !important;
          background-color: get-color-by-background($background-color, var.$black, #ffffff);
          font-family: var.$font-heading;
        }

        .d-flex.flex-row.justify-content-center.align-items-start {
          align-items: center !important;
        }

        @media screen and (max-width: 991px) {
          margin-bottom: 0;
        }

        @content;
      }
    }
  }
}
