@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Poll component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin poll-style($theme: theme('poll', var.$primary-color, #ffffff, #ffffff, var.$primary-color)) {
  fgb-survey-item-poll {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .survey-container {
      border: 1px solid get-color-by-background($background-color, $tertiary-color, var.$disabled-color);
    }

    .answer-text-landscape {
      color: get-color-by-background($secondary-color);
      background-color: $secondary-color;
    }

    .poll-percentage {
      color: get-color-by-background($secondary-color);
    }

    .progress {
      background-color: $secondary-color !important;
    }

    .answer-container:not(.selected) {
      .answer-text,
      .answer-text-landscape {
        color: rgba(get-color-by-background($background-color), 0.2);
        opacity: 1;
      }

      .progress-bar {
        background-color: rgba($primary-color, 0.3);
      }

      img {
        opacity: 0.8;
        filter: brightness(0.3);
      }
    }

    .answer-container.selected {
      .progress-bar {
        background-color: rgba($primary-color, 0.6);
      }
    }

    .answer-text {
      border-color: get-color-by-background($background-color, $secondary-color, var.$disabled-color) !important;
      background-color: get-color-by-background($background-color, $secondary-color, var.$white) !important;
    }

    .answer-container {
      .answer-text,
      .answer-text-landscape {
        background-color: $primary-color;
      }
    }

    .image-container {
      background-color: transparent;
    }
  }
}
