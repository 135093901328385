@use 'club-overrides' as var;
@use 'partials' as *;

@mixin faq-style($theme: theme('faq', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgbcl-faq-section {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .faq-container .accordion .accordion-button {
      background-color: get-color-by-background($background-color, $tertiary-color, var.$white) !important;
      box-shadow: none;
    }

    .accordion-button[aria-expanded='true'] {
      border-bottom: 1px solid get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }

    .faq-container .accordion .accordion-button:not(.collapsed) {
      background-color: $primary-color !important;
    }

    .accordion-header {
      font-family: var.$font-default;

      .accordion-button {
        font-weight: bold;
      }
    }

    .faq-section-title {
      text-transform: uppercase;
      color: get-color-by-background($background-color);
    }

    .faq-section-card-description,
    .faq-section-card-title {
      font-size: 14px;
    }

    .accordion-collapse {
      $bg-accordion-body: get-color-by-background($background-color, var.$black, var.$white);
      background-color: $bg-accordion-body !important;
      color: get-color-by-background($bg-accordion-body) !important;
    }

    .accordion-item {
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }

    .faq-section-card-title[aria-expanded='true'] {
      color: var.$white !important;
    }

    fgbcl-faq-section-card {
      div.mt-3 {
        margin-top: 10px !important;
      }
    }

    .accordion-button[aria-expanded='true'] {
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e") !important;
      }
    }

    @content;
  }
}
