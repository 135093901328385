@mixin set-theme($theme) {
    @if (
      map-has-key($theme, 'name') and
        map-has-key($theme, 'primary') and
        map-has-key($theme, 'secondary') and
        map-has-key($theme, 'tertiary') and
        map-has-key($theme, 'accent')
    ) {
      $theme-name: map-get($theme, 'name');

      .#{$theme-name}-theme {
        @content;
      }
    }
    @else{
        @error 'Invalid theme';
    }

}