@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Badges component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin badges-style($theme: theme('badges', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgbcl-badge-overview-list {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .badge-subheading {
      text-transform: uppercase;
      color: get-color-by-background($background-color);
    }

    .badge-container {
      background-color: $tertiary-color;
      border-color: get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125));
    }

    .badge-title {
      color: get-color-by-background($background-color);
    }

    .scorecard-label-ellipsis {
      max-width: 120px;
    }

    fgbcl-badge-list-item {
      button.mt-3 {
        margin-top: 10px !important;
      }
    }
  }
}
