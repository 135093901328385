@use 'club-overrides' as var;
@use 'partials' as *;

@mixin score-predictor-style($theme: theme('scored-predictor', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgb-score-predictor-item {
    .survey-body,
    #numberInput {
      color: get-color-by-background($background-color) !important;
      background-color: get-color-by-background($background-color, #000000, #ffffff) !important;
    }

    .survey-container {
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }

    .survey-header {
      background-color: get-color-by-background($background-color, $tertiary-color, $primary-color) !important;
    }

    #numberInput {
      border-radius: 8px;
      border-color: get-color-by-background($background-color, $secondary-color, var.$disabled-color) !important;
    }

    .score-predictor-countdown .material-icons,
    .countdown-timer {
      color: get-color-by-background($background-color, $secondary-color, $primary-color) !important;
    }

    .score-predictor-footer-text {
      color: get-color-by-background($background-color, #ffffff, #707070) !important;
    }

    .arrow-container .arrow {
      border-color: get-color-by-background($background-color, $secondary-color, $primary-color) !important;
    }

    .entry-window-countdown {
      background-color: get-color-by-background($background-color, #272727, #dedddd) !important;

      p {
        font-size: 14px !important;
        margin: 0 !important;
      }

      color: #707070 !important;

      .countdown-timer {
        color: get-color-by-background($background-color, #ffffff, #707070) !important;
      }
    }

    .score-predictor-title {
      font-size: 20px;
    }
  }

  .score-predictor-earn-points-card {
    background-color: get-color-by-background($background-color, $tertiary-color, #ffffff) !important;
    border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;

    h5 {
      text-align: center;
      color: get-color-by-background($background-color, #ffffff, var.$primary-color) !important;
    }
  }

  @media (max-width: 991px) {
    .score-predictor-earn-points-card {
      min-height: 78px !important;

      h5 {
        max-width: 90%;
      }
    }
  }
}
