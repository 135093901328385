@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Announcement component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin announcement-style(
  $theme: theme('announcement', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color),
  $variables...
) {
  fgbcl-announcements {
    $var: keywords($variables);
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .announcements-container {
      background-color: $background-color;
      border: 1px solid get-color-by-background($secondary-color, $accent-color, var.$disabled-color) !important;
    }

    .announcements-main-title {
      text-transform: uppercase;
      margin-top: 0;
    }

    .announcements-carousel {
      min-height: 270px !important;
    }

    .announcements-text {
      color: get-color-by-background($background-color);
    }

    .announcement-link {
      color: $primary-color;
    }

    a {
      color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
    }

    @media screen and (max-width: 991px) {
      .announcement-link {
        margin-top: 20px;
      }
    }

    @content;
  }
}
