@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Lotto details component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin lotto-details-style($theme: theme('lotto-details', var.$primary-color, #ffffff, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgbcl-lotto-details {
    .lotto-icons-color,
    .countdown-timer {
      color: get-color-by-background($background-color, #ffffff, #999999);
    }

    .countdown-timer {
      color: get-color-by-background($background-color, #ffffff, var.$error-color);
    }

    .lotto-detail-name {
      text-transform: uppercase !important;
      font-size: 20px !important;
    }

    .mt-lg-3.pt-lg-3 {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }

    hr.border-top {
      border-color: get-color-by-background($background-color, #272727, var.$disabled-color) !important;
    }

    .number-input-decrement-button,
    .number-input-increment-button {
      background: none !important;
      color: get-color-by-background($background-color, #3ca963, black) !important;
      background-color: get-color-by-background($background-color, #002919, var.$white) !important;
    }

    .number-input-decrement-button:disabled,
    .number-input-increment-button:disabled {
      color: get-color-by-background($background-color, black, #dedddd) !important;
      background-color: get-color-by-background($background-color, #272727, inherit) !important;
    }

    #amountInput,
    input {
      color: get-color-by-background($secondary-color) !important;
      border: none !important;
    }

    .accordion-item,
    .accordion-button.collapsed {
      background-color: inherit !important;
    }

    @content;
  }

  .lotto-modal-title,
  .lotto-modal-item-name,
  .lotto-modal-points-value,
  .lotto-modal-entries,
  .rewards-item-description,
  .rewards-item-terms-text,
  .form-check label,
  .rewards-item-name,
  .purchase-confirmation-name,
  .purchase-confirmation-title {
    color: get-color-by-background($background-color);
  }

  .accordion-button {
    color: get-color-by-background($background-color) !important;

    &::after {
      $black-stroke: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e";
      $white-stroke: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e";
      background-image: url(get-color-by-background($background-color, $white-stroke, $black-stroke)) !important;
    }
  }

  .btn-rewards-redeem {
    border: none !important;

    &:disabled {
      color: get-color-by-background($background-color, white, #707070) !important;
      background-color: get-color-by-background($background-color, #707070, #dedddd) !important;
    }
  }

  .loyalty-details .btn:disabled {
    opacity: 1 !important;
  }

  .state label:before {
    background-color: get-color-by-background($background-color, #ffffff, transparent) !important;
  }

  .btn-rewards-redeem {
    border: none !important;
  }

  .links a {
    color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
  }

  fgb-lotto-detail-page {
    .back-btn {
      color: get-color-by-background($background-color, #ffffff, var.$error-color);
    }
  }

  .loyalty-details-image.card {
    border-color: get-color-by-background($background-color, $primary-color, var.$disabled-color) !important;
  }

  .confirm-modal {
    hr {
      // Hack, the mixin is intended for colors but can be used as value
      opacity: get-color-by-background($background-color, 1, 0.25);
      border-color: get-color-by-background($background-color, $accent-color, #dedddd) !important;
    }

    .modal-footer {
      border-top: 1px solid get-color-by-background($background-color, $accent-color, #dedddd) !important;
    }

    div.d-flex:not(.modal-footer) {
      border-radius: 20px;
      border: 1px solid get-color-by-background($background-color, $accent-color, white) !important;
    }
    .btn-cancel {
      color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
    }
  }

  .modal-content {
    fgbcl-confirm-modal-terms {
      hr {
        border-color: get-color-by-background($background-color, $primary-color, rgba(0, 0, 0, 0.125)) !important;
      }

      .btn-success {
        background-color: #46a939;
      }

      button,
      button:disabled {
        color: #ffffff;
      }
    }
  }

  fgbcl-lottos-list {
    .no-results-placeholder-div {
      border-radius: var.$border-radius;
      background-color: get-color-by-background($background-color, $tertiary-color, #ffffff);
      border-color: get-color-by-background($background-color, $primary-color, #dedddd) !important;
    }
  }
}
