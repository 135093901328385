@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Marketplace details component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin marketplace-details-style($theme: theme('marketplace-details', var.$primary-color, #ffffff, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgbcl-marketplace-details {
    #header-name,
    #points-value,
    #stock,
    .rewards-item-description,
    .rewards-item-terms-text,
    .form-check label {
      color: get-color-by-background($background-color);
    }

    #header-name {
      text-transform: uppercase !important;
      font-size: 20px !important;
      margin-top: 5px !important;
    }

    .mt-lg-3.pt-lg-3 {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }

    .marketplace-details-points-icon,
    .marketplace-details-stock-icon {
      color: get-color-by-background($background-color, #ffffff, #999999);
    }

    .rewards-item-details {
      color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
    }

    #amountInput,
    .select-attribute-modal-btn,
    .selected-attribute-modal-btn,
    input {
      color: get-color-by-background($secondary-color) !important;
      border: none !important;
    }

    .accordion-button {
      color: get-color-by-background($background-color) !important;

      &::after {
        $black-stroke: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e";
        $white-stroke: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e";
        background-image: url(get-color-by-background($background-color, $white-stroke, $black-stroke)) !important;
      }
    }

    .number-input-decrement-button,
    .number-input-increment-button {
      background: none !important;
      color: get-color-by-background($background-color, #3ca963, black) !important;
      background-color: get-color-by-background($background-color, #002919, var.$white) !important;
    }

    .number-input-decrement-button:disabled,
    .number-input-increment-button:disabled {
      color: get-color-by-background($background-color, black, #dedddd) !important;
      background-color: get-color-by-background($background-color, #272727, inherit) !important;
    }

    .btn-rewards-redeem {
      border: none !important;

      &:disabled {
        color: get-color-by-background($background-color, white, #707070) !important;
        background-color: get-color-by-background($background-color, #707070, #dedddd) !important;
      }
    }

    .loyalty-details .btn:disabled {
      opacity: 1 !important;
    }

    .custom-field-form {
      background-color: get-color-by-background($background-color, $tertiary-color, #ffffff) !important;
      color: get-color-by-background($background-color) !important;
      border: 1px solid get-color-by-background($background-color, $accent-color, #dedddd) !important;
    }

    fgbcl-reward-status {
      .bg-not-enough-points,
      .bg-limit-reached {
        background-color: get-color-by-background($background-color, black, rgba(224, 62, 62, 0.2)) !important;

        label {
          color: get-color-by-background($background-color, white, #e03e3e) !important;
        }
      }

      .bg-coming-soon,
      .bg-sold-out {
        background-color: get-color-by-background($background-color, #707070, rgba(112, 112, 112, 0.2)) !important;

        label,
        .countdown-timer {
          color: get-color-by-background($background-color, white, #707070) !important;
        }
      }
    }

    .display-name-custom-field {
      color: get-color-by-background($background-color, white, #707070) !important;
    }

    .display-name-custom-field::after {
      background-color: inherit !important;
    }

    .bid-amount-input {
      background-color: inherit !important;
    }

    .accordion-item,
    .accordion-button.collapsed {
      background-color: inherit !important;
    }

    .marketplace-terms,
    .custom-field-title {
      color: get-color-by-background($background-color);
    }

    .marketplace-terms:before {
      background-color: get-color-by-background($background-color, #ffffff, transparent);
    }

    hr.border-top {
      border-color: get-color-by-background($background-color, #272727, var.$disabled-color) !important;
    }

    @content;
  }

  fgb-marketplace-detail-page {
    .back-btn {
      color: get-color-by-background($background-color, #ffffff, var.$error-color);
    }

    .back-btn-container {
      background-color: get-color-by-background($background-color, #000000, #ffffff);
    }
  }

  .bg-item-locked {
    background-color: get-color-by-background($background-color, #707070, #ffffff) !important;
  }

  .offcanvas-attribute {
    border: get-color-by-background($background-color, none, 1px solid #dedddd) !important;
    background-color: get-color-by-background($background-color, $tertiary-color, #ffffff) !important;

    .offcanvas-attributes-title,
    .material-icons,
    .offcanvas-attribute-btn {
      color: get-color-by-background($background-color) !important;
    }

    .material-icons {
      font-size: 20px;
    }

    .offcanvas-attribute-btn {
      background-color: inherit !important;
      border-color: get-color-by-background($background-color, $accent-color, #dedddd) !important;
    }
  }

  .form-check {
    &:has(input[type='checkbox']) {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }

      label {
        margin: 0 !important;
      }
    }

    &:has(input[type='checkbox']:checked) {
      label {
        color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
      }

      input {
        background-color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
      }
    }
  }

  :has(fgbcl-marketplace-custom-fields) {
    margin-top: 0 !important;

    .form-check {
      margin-top: 0 !important;
    }
  }

  .card.loyalty {
    border-color: get-color-by-background($background-color, $primary-color, var.$disabled-color) !important;
  }

  .marketplace-attribute {
    .btn-select-attribute,
    select {
      color: get-color-by-background($background-color) !important;
      background-color: get-color-by-background($background-color, $tertiary-color, #ffffff) !important;
      border-color: get-color-by-background($background-color, $accent-color, #dedddd) !important;
    }
    .selected-label {
      font-size: 12px !important;
      color: get-color-by-background($background-color) !important;
    }
  }

  .form-attribute-label {
    color: get-color-by-background($background-color, white, #707070) !important;
  }

  .marketplace-attribute-modal {
    .attributes-modal-title,
    .confirmation-attribute-value {
      color: get-color-by-background($background-color);
    }

    .modal-header {
      margin-bottom: 25px;
      border-color: get-color-by-background($background-color, $primary-color, rgba(0, 0, 0, 0.125)) !important;
    }

    .close-modal {
      color: #999999 !important;
    }

    .attribute-box {
      background-color: transparent !important;
      color: get-color-by-background($secondary-color) !important;
      border: 1px solid get-color-by-background($secondary-color, $primary-color, #dedddd) !important;

      &.btn-selected-attribute {
        background-color: $primary-color !important;
        color: get-color-by-background($primary-color) !important;
      }
    }
  }

  .rewards-button-background {
    background-color: get-color-by-background($background-color, $secondary-color, #f7f7f7) !important;
  }

  .modal-content {
    fgbcl-marketplace-purchase-confirmation {
      .purchase-confirmation-title,
      #points-value,
      .purchase-confirmation-name,
      .confirmation-attribute-name,
      .confirmation-attribute-value,
      span {
        color: get-color-by-background($background-color) !important;
      }

      hr {
        // Hack, the mixin is intended for colors but can be used as value
        opacity: get-color-by-background($background-color, 1, 0.25);
        border-color: get-color-by-background($background-color, $accent-color, #dedddd) !important;
      }

      div.d-flex:not(.modal-footer) {
        border-radius: 20px;
        border: 1px solid get-color-by-background($background-color, $accent-color, white) !important;
      }

      .btn-purchase-cancel {
        color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
      }
    }
  }

  fgbcl-marketplace-list {
    .no-results-placeholder-div {
      border-radius: var.$border-radius;
      background-color: get-color-by-background($background-color, $tertiary-color, #ffffff);
      border-color: get-color-by-background($background-color, $primary-color, #dedddd) !important;
    }
  }
}
