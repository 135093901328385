@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Tier component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin transaction-history-style($theme: theme('transaction-history', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgb-transaction-history {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .history-card {
      background-color: get-color-by-background($background-color, var.$black, var.$white) !important;
      border-color: get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125)) !important;
    }

    .from-date-text,
    .to-date-text {
      color: get-color-by-background($background-color);
    }

    button[type='button']:not(.search-button) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .date-range-picker .btn.date-range-picker-icon {
      border-left: 1px solid get-color-by-background($background-color, var.$input-border-color, #dedddd) !important;
    }

    input,
    input:focus {
      color: get-color-by-background($background-color, #ffffff, var.$black) !important;
      border: 1px solid get-color-by-background($background-color, var.$input-border-color, #dedddd) !important;
      background-color: get-color-by-background($background-color, var.$black, var.$white) !important;
      height: 34px !important;
    }

    button:not(.ngb-dp-arrow-btn):not(.search-button) {
      border: 1px solid get-color-by-background($background-color, var.$input-border-color, #dedddd) !important;
      background-color: get-color-by-background($background-color, var.$black, var.$white) !important;
      height: 34px !important;
      border-left: 1px solid get-color-by-background($background-color, var.$input-border-color, #dedddd) !important;
    }

    .transaction-text {
      &.text-success {
        color: get-color-by-background($background-color, var.$white, var.$success-color) !important;
      }

      &.text-danger {
        color: get-color-by-background($background-color, var.$white, var.$error-color) !important;
      }
    }

    fgbcl-date-range-picker {
      .material-icons {
        font-size: 24px !important;
        color: get-color-by-background($background-color) !important;
      }
    }

    fgb-transaction-list {
      .card {
        background-color: get-color-by-background($background-color, var.$black, var.$white) !important;
        color: get-color-by-background(get-color-by-background($background-color, var.$black, var.$white)) !important;
        border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
      }
    }

    .search-button {
      background-color: $primary-color !important;
    }

    @content;
  }
}
