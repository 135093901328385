@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Account details component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin filter-modal-style($theme: theme('filter-modal', var.$primary-color, #f3f3f3, #ffffff, var.$gold-color)) {
  .filter-modal {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .filter-result,
    .filter-select-option {
      background-color: $background-color !important;
      border-color: get-color-by-background($background-color, $accent-color, #dedddd);

      span.text-primary {
        color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
      }
    }

    .filter-modal-header,
    .filter-modal-option {
      padding: 15px 0px;
      margin: 0 15px;
      border-bottom-color: get-color-by-background($background-color, $accent-color, rgba(128, 128, 128, 0.5));
    }

    .filter-label {
      color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
    }

    .form-check-input:checked[type='checkbox'] {
      background-color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
      color: get-color-by-background($background-color, #000000, #ffffff) !important;
      $white-stroke: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e";
      $black-stroke: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e";
      // This mixin is intended for colors but can be used as value as hack
      background-image: url(get-color-by-background($background-color, $black-stroke, $white-stroke));
      border-color: get-color-by-background($background-color, $accent-color, #dedddd) !important;
    }

    .form-check-input[type='checkbox'] {
      border-color: get-color-by-background($background-color, #ffffff, #dedddd) !important;
      background-color: get-color-by-background($background-color, inherit, #ffffff) !important;
    }

    label {
      color: get-color-by-background($background-color) !important;
    }

    .options-list {
      :has(input[type='checkbox']:checked) {
        label {
          color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
        }
      }
    }

    .material-icons {
      font-size: 20px;
      color: get-color-by-background($background-color, #ffffff, #707070) !important;
    }

    #offcanvasBottom {
      background-color: get-color-by-background($background-color, $tertiary-color, #ffffff) !important;

      .bg-white {
        background-color: inherit !important;
      }
    }

    @content;
  }
}
