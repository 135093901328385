/* THEME */

$light-theme: true;

/* COLOURS */

$primary-color: #00693f;
$secondary-color: #ba1b2c;
$tertiary-color: #f7883e;
$quaternary-color: #245953;
$gold-color: #c99a3d;
$primary-alternate: #fff;

$success-color: #00693f;
$error-color: #dc3545;
$warning-color: #ff854f;
$info-color: #707070;
$grey-color: #999999;
$muted-color: #f7f7f7;
$disabled-color: #dedddd;
$payment-color: #04955f;
$light-grey-color: #f3f3f3;
$dark-green: #002919;
$light-green: #3ca963;
$frozen-color: #67a5ff !default;
$white: #fff;
$black: #000;
$dark-grey: #272727;
$input-border-color: #163c26;

$auction-color: $secondary-color;
$marketplace-color: $primary-color;
$lotto-color: $tertiary-color;
$wallet-color: $primary-color;
$points-color: $primary-color;
$ecash-color: $primary-color;
$events-color: $quaternary-color;
$predictor-color: $primary-color;
$poll-color: $primary-color;
$quiz-color: $secondary-color;

$marketplace-0-color: $primary-color; // FortressGB
$marketplace-1-color: $primary-color; // Unique Code
$marketplace-2-color: $primary-color; // Download
$marketplace-3-color: $events-color; // Event
$marketplace-4-color: $tertiary-color; // Product
$wallet-color: $secondary-color;
$wallet-redeemed-color: $muted-color;

$navbar-color: $black;
$navbar-link-color: $white;
$navbar-link-active-color: $white;
$navbar-progress-color: $primary-color;
$navbar-secondary-color: $grey-color;

/* FONTS */

@font-face {
  font-family: 'Poppins';
  font-weight: 700 400;
  src: url('../app/fonts/poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PFSpekkVAR';
  font-weight: 700 400;
  src: url('../app/fonts/PFSpekkVAR/PFSpekkVAR-Bold.woff') format('truetype');
}

$font-default: 'Open Sans', Arial, sans-serif;
$font-heading: 'PFSpekkVAR', Arial, sans-serif;
$dark: #212529;
$light: #f8f9fa;

/* GENERAL */

$navbar-height: 54px;
$navbar-mobile-height: 50px;
$footer-height: 150px;
$footer-mobile-height: 150px;
$panel-height: 150px;
$cta-height: 50px;
$main-padding-bottom: 40px;

$loyalty-image-height: 390px;

$border-radius: 20px;

$card-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.15);
$nav-secondary-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);

$read-more-overlay-enabled: false;

/* BOOTSTRAP OVERRIDES */

$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'tertiary': $tertiary-color,
  'quaternary': $quaternary-color,
  'primary-alternate': $primary-alternate,
  'muted': $muted-color,
  'disabled': $disabled-color,
  'info': $info-color,
  'grey': $grey-color,
  'success': $success-color,
  'danger': $error-color,
  'auction': $auction-color,
  'marketplace': $marketplace-color,
  'mp-unique-code': $tertiary-color,
  'mp-download': $secondary-color,
  'mp-event': $events-color,
  'mp-virtual-event': $events-color,
  'mp-product': $tertiary-color,
  'lotto': $lotto-color,
  'wallet': $wallet-color,
  'points': $points-color,
  'ecash': $ecash-color,
  'predictor': $predictor-color,
  'poll': $poll-color,
  'quiz': $quiz-color,
  'affiliate': $primary-color,
  'transfer': $primary-color,
  'white': #ffffff,
  'black': #000000,
  'light-grey': $light-grey-color,
  'dark-green': $dark-green,
  'light-green': $light-green,
  'sold-out': #70707033,
  'coming-soon': #70707033,
  'item-locked': #70707033,
  'limit-reached': #e03e3e33,
  'not-enough-points': #e03e3e33,
  'add-card': $primary-color,
  'active-card': $primary-color,
  'inactive-card': $disabled-color,
  'frozen-card': $frozen-color,
  'language-selector': $white,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

$sizes: (
  90: 90%,
);

$grid-gutter-width: 22px;

$enable-rounded: true;
$enable-validation-icons: false;

$border-radius-base: 4px;

$box-shadow-sm: 0 0 0.25rem rgba(#000, 0.15);
$box-shadow: 0 0.1rem 0.5rem rgba(#000, 0.15);

$date-select-box-shadow: 0 0 0 0.2rem rgb(60 85 124 / 25%);

/* Reward status disabled overrides */

$reward-status-disabled-colors: (
  'coming-soon': (
    $grey-color,
    $white,
  ),
  'not-enough-points': (
    $grey-color,
    $white,
  ),
  'sold-out': (
    $grey-color,
    $white,
  ),
  'limit-reached': (
    $grey-color,
    $white,
  ),
  'item-locked': (
    $grey-color,
    $white,
  ),
);

$registration-terms-and-condition-title-color: $black;
$registration-terms-and-condition-title-mobile-text-align: left;
