@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Announcement component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin secondary-navbar-style($theme: theme('secondary-navbar', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgb-account-nav,
  fgb-mixed-loyalty-nav,
  fgb-game-nav,
  fgb-challenges-nav {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    @if map-get($theme, 'tertiary') == #002919 {
      $background-color: var.$black;
    }

    .nav-secondary {
      background-color: $background-color;
      color: get-color-by-background($background-color, $primary-color, #ffffff);
    }

    .nav-item {
      border-bottom: 3px solid inherit;
    }

    .nav-item.active {
      border-color: get-color-by-background($background-color, #ffffff, $primary-color) !important;

      .material-icons,
      label {
        color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
      }
    }

    @media screen and (max-width: 991px) {
      .nav-item {
        flex: 1 !important;
      }
    }

    @media screen and (min-width: 992px) {
      .nav-item.active {
        border-color: inherit !important;
      }
    }
  }
}
