@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Contact Us component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin contact-us-style($theme: theme('contact-us', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgbcl-contact-us {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .contact-us-box {
      margin-top: 30px !important;
      background-color: $background-color !important;
      border: 1px solid get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }

    textarea {
      color: get-color-by-background($background-color) !important;
      background-color: $background-color !important;
      padding: 10px !important;
    }

    .contact-us-dropdown,
    .contact-us-send-button {
      background-color: $primary-color;
    }

    .contact-us-dropdown {
      border: none;
    }

    .contact-us-send-button:disabled {
      opacity: 0.5;
    }

    .contact-us-input-group {
      border-width: 1px;
      border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    }

    @media screen and (max-width: 991px) {
      .contact-us-box {
        margin-top: 20px !important;
      }

      .col-md-8 {
        max-width: 100% !important;
      }
    }
  }
}
