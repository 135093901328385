@use 'club-overrides' as var;
@use 'partials' as *;

@mixin tier-v2-style($theme: theme('tier-v2', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgb-tier-v2 {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .tier-v2 {
      border: 1px solid var.$gold-color !important;

      .points-value {
        h3 {
          margin-left: -20%;
        }
      }
    }
  }
}
