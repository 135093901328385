@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Wallet redeem list component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin wallet-redeem-list-style($theme: theme('wallet-redeem-list', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgbcl-rewards-wallet-redeem-list {
    .rewards-wallet-redeem-title {
      text-transform: uppercase;
    }

    .marketplace-wallet-expiry {
      color: get-color-by-background($background-color, #ffffff, var.$error-color);
    }

    .rewards-wallet-redeem-title {
      color: get-color-by-background($background-color);
    }

    .bg-marketplace-wallet,
    .redeem-card {
      background-color: $background-color !important;
      border: 1px solid get-color-by-background($secondary-color, $accent-color, rgba(0, 0, 0, 0.125)) !important;
    }

    .purchase-title {
      color: get-color-by-background($background-color);
    }

    .wallet-unique-code-item {
      color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
    }

    .redeem-card {
      min-height: 210px !important;
    }

    @media screen and (max-width: 991px) {
      .redeem-card {
        height: 255px !important;
      }
    }

    @content;
  }

  .no-wallet-items-container {
    color: get-color-by-background($background-color);
    background-color: get-color-by-background($background-color, $tertiary-color, #ffffff);
    border-color: get-color-by-background($background-color, $primary-color, #dedddd) !important;
  }
}
