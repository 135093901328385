@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Member card component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin member-card-style($theme: theme('member-card', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgb-member-card {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .member-card {
      border-color: get-color-by-background($background-color, $tertiary-color, rgba(0, 0, 0, 0.125)) !important;
    }
  }
}
