@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Tier component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin scorecard-style($theme: theme('scorecard', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color), $variables...) {
  $var: keywords($variables);
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgbcl-scorecard-carousel,
  fgbcl-scorecard-list-view {
    .badge-carousel-subheading {
      text-transform: uppercase;
      color: get-color-by-background($background-color);
    }

    .container.py-4 {
      padding-top: 0 !important;
    }

    .scorecard {
      background-color: $background-color;
    }

    #scorecard-punchcard-name,
    #scorecard-milestone-name,
    .scorecard-milestone-value,
    #scorecard-punchcard-actions-completed,
    #scorecard-actions-steps,
    #scorecard-number-name,
    .punchcard-info-btn,
    .milestone-info-btn,
    .scorecard-featured-rewards-link:not(.material-icons) {
      color: get-color-by-background($background-color);
    }

    #scorecard-actions-completed-milestones {
      margin-bottom: 25px;
    }

    .scorecard-one-punch-incomplete {
      opacity: 0.4 !important;
    }

    .scorecard-punchcard-prize-label,
    #scorecard-punchcard-description,
    #scorecard-milestone-description,
    #scorecard-completed-text,
    .scorecard-milestones-prize-label,
    #scorecard-number-description {
      color: get-color-by-background($background-color);
    }

    .scorecard-milestones-prize-label,
    .scorecard-punchcard-prize-label {
      margin-right: 5px;
    }

    .punch-spacing {
      justify-content: space-between;
    }

    #scorecard-number-actions-completed {
      color: get-color-by-background($background-color, #ffffff, $primary-color);
    }

    .scorecard-container,
    .scorecard {
      border-color: get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125));
    }

    .scorecard-footers,
    .scorecard-milestones-footer-container,
    .scorecard-punchcard-footer-container {
      border-color: get-color-by-background($background-color, $accent-color, $primary-color);
    }

    .scorecard-locked-header,
    .scorecard-locked-footer {
      border-color: get-color-by-background($background-color, $accent-color, #dedddd);
    }

    .bg-disabled {
      background-color: #cbcbcb !important;
    }

    .scorecard-locked {
      border-radius: var.$border-radius;
    }

    .scorecard-locked-name {
      font-size: 16px;
      color: get-color-by-background($background-color);
      opacity: 0.2;
    }

    @content;
  }

  .scorecard-description-modal,
  .modal-content,
  .scorecard-description-modal-carousel {
    background-color: $tertiary-color;
    border-bottom: none;
  }

  .scorecard-description-modal .scorecard-description-modal-dash-line-top {
    padding-top: 30px;
    margin-bottom: 20px;
  }

  .scorecard-modal-banner {
    height: 150px !important;
  }

  #scorecard-actions-completed-milestones {
    margin-bottom: 30px !important;
  }

  #scorecard-modal-name,
  .scorecard-description-modal-prize-v2,
  .scorecard-description-modal-list-prize,
  .scorecard-description-modal-terms-active,
  .short-long-description-text,
  .modal-close-button,
  .scorecard-description-modal-prize-description,
  .scorecard-Milestone-Milestones-Value-model-v2,
  .scorecard-description-modal-carousel .previous,
  .scorecard-description-modal-carousel .next,
  .scorecard-description-modal-carousel .current-step,
  .scorecard-description-modal-carousel .total-step {
    color: get-color-by-background($background-color);
  }

  .scorecard-description-modal-congratulation-title {
    font-family: var.$font-heading !important;
    color: get-color-by-background($background-color, var.$white, var.$primary-color) !important;
    font-weight: bold;
  }

  .scorecard-description-modal {
    .short-long {
      margin-bottom: 10px;

      .btn-toggle {
        color: get-color-by-background($background-color);
      }
    }
  }

  .scorecard-description-modal-terms-active {
    color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
  }

  .scorecard-description-modal-congratulation-subtitle {
    font-size: 12px !important;
  }

  .scorecard-description-modal-prizebox {
    padding: 11px !important;
  }

  .scorecard-description-modal-description-box {
    margin-top: 5px;
  }

  .scorecard-description-modal-carousel,
  .scorecard-description-modal {
    border: 1px solid get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125));
  }

  .scorecard-description-modal {
    border-radius: 20px;
  }

  .scorecard-description-modal-next-challenge-box {
    border-color: get-color-by-background($background-color, $accent-color, #dedddd) !important;
  }

  .scorecard-description-modal-challenge-locked-box {
    border-color: #dedddd !important;
  }

  .scorecard-description-modal-prizebox {
    border: 1px solid get-color-by-background($background-color, $accent-color, $primary-color) !important;
  }

  .scorecard-description-modal-carousel .dash {
    background: get-color-by-background($background-color);
  }

  #scorecard-actions-completed-number {
    font-size: 30px;
  }

  #scorecard-name {
    $bg-one-punch-complete: get-color-by-background($background-color, #707070, #dedddd);
    background-color: $bg-one-punch-complete !important;
    color: get-color-by-background($bg-one-punch-complete, #ffffff, #000000) !important;

    &.scorecard-one-punch-complete-name {
      color: #ffffff !important;
      background-color: $primary-color !important;
    }
  }

  .corecard-featured-rewards-link,
  .scorecard-carousel-links,
  .scorecard-description-modal-more-btn {
    color: get-color-by-background($background-color);
  }

  .action-img-container {
    height: 30px !important;
    width: 30px !important;
  }

  #scorecard-actions-completed-number {
    margin-left: 0 !important;
    margin-right: 0 !important;
    color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
  }

  .accordion-item,
  .accordion-button {
    background-color: inherit !important;
  }

  hr.border-top,
  #scorecard-actions-completed-number {
    border-color: get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;
    opacity: 1;
  }

  fgbcl-scorecard-description-modal-carousel {
    .current-step,
    .total-step,
    .material-icons {
      color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
    }
    .dash {
      background: get-color-by-background($background-color, #ffffff, $primary-color) !important;
    }
  }
}
