@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Account details component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin reward-item-style($theme: theme('reward-item', var.$primary-color, #f3f3f3, #ffffff, var.$gold-color)) {
  .loyalty-item-container,
  fgbcl-mixed-loyalty-item {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .rewards-item-panel-name {
      color: get-color-by-background($background-color) !important;
    }

    .rewards-item-panel-value {
      color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
    }

    .text-rewards-new {
      color: $primary-color !important;
    }

    @content;
  }
}
