@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Earn component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin earn-points-style($theme: theme('earn-points', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgbcl-earn-points {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $primary-color;

    .earn-sub-heading {
      color: get-color-by-background($tertiary-color) !important;
    }

    .earn-card-body {
      background-color: $background-color !important;
    }

    ngb-accordion:first-of-type {
      .card-header .mt-3 {
        margin-top: 0 !important;
      }
    }

    .earn-points-container {
      h2 {
        margin-top: 20px !important;
      }
    }

    fgbcl-earn-points-card {
      .col-9.col-sm-8 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
        padding-right: 30px;
      }

      .earn-card-body {
        border: 1px solid get-color-by-background($background-color, $accent-color, $primary-color) !important;
      }
    }
  }
}
