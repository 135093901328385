@use 'partials' as *;
@use 'mixins' as *;
@use 'component-styles' as component;

$dark-theme: theme('dark', $primary-color, #272727, $dark-green, $primary-color);
$poll-theme: theme('poll', $primary-color, #000000, $dark-green, $primary-color);
$contact-us-theme: theme('contact-us', $primary-color, #272727, $dark-green, $gold-color);
$earn-points-theme: theme('earn-points', $dark-green, #272727, $dark-green, $gold-color);
$login-theme: theme('login', $primary-color, #272727, #00120b, $dark-green);
$marketplace-theme: theme('dark', $primary-color, #000000, $dark-green, $gold-color);
$announcement-theme: theme('announcement', $primary-color, #000000, $dark-green, $gold-color);
$gold-theme: theme('gold', $primary-color, #272727, $dark-green, $gold-color);
$score-predictor-theme: theme('score-predictor', $primary-color, $gold-color, #002919, #163c26);

@include set-theme($dark-theme) {
  color: #ffffff;
  $bg-color: #00120b;
  background-color: $bg-color;

  @include component.tier-style($gold-theme, $border-color: $primary-color);
  @include component.voucher-style($gold-theme);
  @include component.scorecard-style($gold-theme);
  @include component.announcement-style($announcement-theme);
  @include component.secondary-navbar-style($dark-theme);
  @include component.account-details-style($gold-theme);
  @include component.transaction-history-style($gold-theme);
  @include component.featured-rewards-style($dark-theme);
  @include component.wallet-redeem-list-style($gold-theme);
  @include component.wallet-redeemed-list-style($gold-theme);
  @include component.wallet-details-style($gold-theme);
  @include component.badges-style($gold-theme);
  @include component.trivia-style($dark-theme);
  @include component.poll-style($poll-theme);
  @include component.earn-points-style($dark-theme);
  @include component.contact-us-style($contact-us-theme);
  @include component.earn-points-style($earn-points-theme);
  @include component.notification-style($gold-theme);
  @include component.login-style($login-theme);
  @include component.points-summary-style($gold-theme);
  @include component.marketplace-details-style($marketplace-theme);
  @include component.lotto-details-style($marketplace-theme);
  @include component.marketplace-purchase-success-style($gold-theme);
  @include component.lotto-purchase-success-style($gold-theme);
  @include component.member-card-style($dark-theme);
  @include component.home-earn-steps-style($gold-theme);
  @include component.faq-style($gold-theme);
  @include component.filter-modal-style($gold-theme);
  @include component.reward-item-style($gold-theme);
  @include component.terms-style($dark-theme);
  @include component.member-upgrade-style($gold-theme);
  @include component.tier-v2-style($gold-theme);
  @include component.score-predictor-style($score-predictor-theme);
  @include component.predictor-style($score-predictor-theme);
  @include component.pick-a-player-style($gold-theme);

  .claimed-text,
  .no-wallet-items,
  .wallet-redeemed-title {
    color: #ffffff !important;
    text-transform: uppercase;
  }

  .right-side-login {
    background-color: #00120b !important;

    input {
      color: get-color-by-background(#00120b);
      background-color: get-color-by-background(#00120b, map-get($login-theme, 'secondary'), #ffffff) !important;
      border-color: get-color-by-background(#00120b, map-get($login-theme, 'primary'), $disabled-color);
    }
  }

  .owl-theme .owl-dots .owl-dot:not(.active) span {
    background: $grey-color;
  }

  .tier-item-list-container {
    .progress {
      background-color: $dark-grey !important;
    }
  }

  fgb-featured-surveys .link-btn {
    color: get-color-by-background(#00120b) !important;
  }

  .form-select ~ label::after {
    background-color: inherit !important;
  }

  .loyalty-details .btn:disabled {
    .svg.svg-primary {
      svg path {
        fill: get-color-by-background(#00120b, $grey-color, $primary-color) !important;
      }
    }
  }

  .earn-page-message {
    color: get-color-by-background(#00120b) !important;
  }

  .no-items.no-results-placeholder-div {
    background-color: get-color-by-background(#00120b, $black, $white) !important;
    border-color: get-color-by-background(#00120b, $gold-color, #dfdfdf) !important;
  }

  .bg-item-locked {
    label {
      color: get-color-by-background(#00120b) !important;
    }
  }
  .status-text-on-not-enough-points,
  .status-text-on-sold-out {
    color: #dedddd;
  }

  .rewards-item-panel-status.text-info,
  .rewards-item-panel-status.text-rewards-success,
  .rewards-item-panel-status.status-text-on-coming-soon,
  .rewards-item-panel-status.status-text-on-limit-reached,
  .text-rewards-success .text-success {
    color: $white !important;
  }

  fgbcl-lottos-list-item {
    .countdown-timer {
      color: $white;
    }
  }

  .answer-text {
    background-color: #272727 !important;
  }

  .predictor-icon,
  .predictor-countdown-icon,
  .entered-predictor,
  .predictor-processed-answer {
    color: $white !important;
  }

  .placeholder-predictor {
    background-color: #002919 !important;
  }

  fgb-survey-item-quiz,
  fgb-score-predictor-item {
    .survey-container,
    .survey-header {
      border-color: $gold-color !important;
    }
  }

  fgb-survey-item-predictor {
    .survey-info {
      border-color: $gold-color !important;
    }

    .border-gold {
      border: 1px solid $gold-color !important;
    }
  }

  .player-not-selected {
    background-color: #272727 !important;
    color: $white !important;
  }

  .leaderboard-colour {
    color: $white !important;
  }

  .leaderboard-nav-item.selected {
    color: $gold-color !important;
    border-bottom: 4px solid $gold-color !important;
  }

  .leaderboard-nav-item {
    color: $white !important;
    border-bottom: 4px solid $white !important;
  }

  fgb-fantasy-leaderboard-page {
    .material-icons.fw-bold.text-black {
      color: $white !important;
    }
  }

  .fantasy-player-history {
    color: $white !important;
  }

  .processed-fantasy {
    color: $gold-color !important;
  }

  .status-text-on-sold-out {
    color: get-color-by-background($bg-color, $white, $disabled-color) !important;
  }

  .item-state,
  .item-count {
    color: get-color-by-background($bg-color, $white, $error-color) !important;
  }
}
