@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Trivia component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin trivia-style($theme: theme('trivia', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color), $variables...) {
  fgb-survey-item-quiz {
    $var: keywords($variables);
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    @if map-get($theme, 'tertiary') == #002919 {
      $background-color: var.$black;
    }

    .survey-container {
      background-color: $background-color;
      border-color: get-color-by-background($background-color, $tertiary-color, var.$disabled-color);
    }

    .survey-header {
      border-bottom: 1px solid get-color-by-background($background-color, $tertiary-color, var.$disabled-color);
    }

    .survey-answer-item {
      border: 1px solid get-color-by-background($background-color, $tertiary-color, var.$disabled-color) !important;
    }
  }
}
