@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Poll component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin terms-style($theme: theme('terms', var.$primary-color, #ffffff, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgbcl-terms-and-conditions {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: get-color-by-background($background-color, var.$white, $primary-color) !important;
      font-weight: bold;
    }

    h6 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }

  .scroll-bottom-wrapper {
    bottom: 40px !important;
  }

  #terms-scroll-button {
    background-color: $primary-color !important;
  }
}
