@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Notification component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin notification-style($theme: theme('notification', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  .notification-dropdown {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    color: get-color-by-background($background-color);
    background-color: $background-color;
    border: 1px solid get-color-by-background($background-color, $accent-color, var.$disabled-color) !important;

    .notification-item,
    .notification-header {
      background-color: $background-color;
    }

    .notification-title {
      color: get-color-by-background($background-color) !important;
    }

    .notification-links,
    .notification-link-url,
    .notification-icon {
      color: get-color-by-background($background-color);
    }

    hr {
      border-color: get-color-by-background($background-color, #ffffff, rgba(0, 0, 0, 0.125));
    }
  }
}
