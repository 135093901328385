@use 'club-overrides' as var;
@use 'partials' as *;

@mixin member-upgrade-style($theme: theme('member-upgrade-card', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  fgb-member-upgrade-card {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .member-upgrade-card {
      border: 1px solid get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125)) !important;
    }
  }
}
