@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Login component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin login-style($theme: theme('account-details', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  // @TODO: need to finish this
  fgb-login-page {
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: get-color-by-background($secondary-color, $secondary-color, $tertiary-color);

    .forgot-password a {
      color: get-color-by-background($background-color, #ffffff, var.$error-color);
    }

    input {
      color: get-color-by-background($background-color);
      background-color: get-color-by-background($background-color, $secondary-color, #ffffff) !important;
      border-color: get-color-by-background($background-color, $primary-color, var.$disabled-color);
    }

    .form-control:focus {
      color: get-color-by-background($background-color);
    }
  }
}
