@import '@fgb/portal-component-library/styles';
@import '/src/sass/variables';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';
@import 'core';

/* ELEMENT STYLING */

html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;
    @if $light-theme {
      background-color: $light-grey-color;
      color: black;
    } @else {
      background: #000;
      color: white;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
.title-font {
  font-weight: normal !important;
  text-transform: none;
  font-family: $font-heading;
}

.font-heading {
  font-family: $font-heading;
}
.font-default {
  font-family: $font-default;
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

/* Form Colors */

.form-control {
  color: #000000;
}

/* eCash Styling */

@media (min-width: 1200px) {
  .topUpModal .modal-dialog {
    max-width: 1140px;
  }
}

.svg {
  &.money-bags {
    height: 25px;
    width: 25px;
  }
}

/* Banner */

.banner {
  background-image: url('~src/assets/images/banner-desktop.jpg');
  background-size: 100% 100px;
  justify-content: left;
  padding-left: 3rem;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  font-size: 1.875rem;

  h1 {
    text-transform: uppercase;
  }

  @media (max-width: 991px) {
    background-size: 100% 70px;
    font-size: 1.375rem;
  }

  @media (max-width: 599px) {
    padding-left: 20px;
    background-image: url('~src/assets/images/banner-mobile.jpg');
  }

  h1 {
    color: $white;
  }
}

.login-banner {
  background-image: url(~src/assets/images/login-registration/login-banner-2024.jpg);
  background-size: cover;
  background-position: center;
}

/* Partners */

.partner-add-svg {
  height: 30px;
  width: 30px;
  svg * {
    stroke: $primary-color;
    fill: white;

    .cls-2 {
      fill: $primary-color;
    }
  }
}

.partner-accept-svg {
  height: 30px;
  width: 30px;
  svg * {
    stroke: $primary-color;
    fill: white;

    .cls-2 {
      fill: $secondary-color;
    }
  }
}

.nav-secondary {
  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      margin: 0;
    }
  }
}

/* CHILDREN OWL CAROUSEL BARCODES */

.children-barcodes {
  .modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid red;
  }
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  color: #000;
  padding: 0 10px 15px 15px;
}

.contact-us-dropdown-div {
  margin-bottom: 20px !important;
}

/* Placeholder Styling */

.no-items {
  min-height: 60px;
  margin-top: 30px;
  background-color: $white;
  border-color: #dfdfdf !important;

  .no-results-placeholder-icon {
    max-width: 200px;
    margin: 18px auto;

    svg {
      height: 75px;
    }

    &.material-icons-two-tone {
      font-size: 4rem;
      margin: 18px auto 0;
    }
  }

  .no-results-placeholder-title {
    font-size: 1rem;
    font-family: $font-heading;
  }

  .no-results-placeholder-text {
    font-size: 0.75rem;
  }

  &.no-results-placeholder-div {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 991px) {
      margin-left: 0 !important;
    }

    .no-results-placeholder {
      font-size: 0.75rem;
    }
  }
}

.increment-button,
.decrement-button {
  svg {
    height: 13px;
    width: 13px;
  }
}

.svg-marketplace-details-icons {
  svg {
    height: 14px;
    width: 14px;
  }
}

.redeem-list-item-icons {
  font-size: 18px;
}

/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  color: $error-color !important;
  border: none !important;
  background-color: transparent !important;
}

.reward-terms input:checked ~ .state label:before {
  background-color: $success-color !important;
  border-color: $success-color !important;
}

.reward-terms .state label:before {
  border: 2px solid;
}

.reward-terms input:checked ~ .state label:after {
  height: 20px;
  width: 20px;
  margin-left: 0px;
  background-image: url('~src/assets/icons/check-icon.svg');
  border-color: $success-color;
}

.all-offers-steps {
  .card-link-earn-step-box {
    .card-title {
      font-size: 14px;
      color: #c80550;
    }
    .color-1 {
      color: #c80550;
    }
    .color-2 {
      color: #82bee6;
    }
    .color-3 {
      color: #7d4605;
    }
    .card-link-earn-step-icon-border-1 {
      border: 1px solid #c80550;
    }
    .card-link-earn-step-icon-border-2 {
      border: 1px solid #82bee6;
    }
    .card-link-earn-step-icon-border-3 {
      border: 1px solid #7d4605;
    }
  }
}

.offer-button {
  span {
    font-family: 'Material Icons';
  }
}

.nav.nav-secondary {
  background-color: #ffffff;
  .nav-item {
    &.active,
    &:active,
    &:hover {
      background-color: transparent;
      border-color: $primary-color;
      label,
      .icon {
        color: $primary-color;
      }
    }
    .icon {
      font-size: 24px;
    }

    &:not(.active) {
      label,
      .icon {
        color: #999999;
      }
    }
    background-color: transparent;
  }
}

.portal-link-card {
  p {
    font-family: $font-heading;
  }
  background: $primary-color;
  @media only screen and (min-width: 992px) {
    height: 200px;
  }
}

.announcements-carousel {
  .owl-item {
    @media only screen and (min-width: 992px) {
      &:not(:first-child) {
        margin-left: 4px !important;
      }

      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-right: 0 !important;
      width: 944px !important;
      padding-left: 8px !important;
    }

    @media only screen and (max-width: 991px) {
      padding-left: unset !important;

      &:first-child {
        padding-left: 0 !important;
      }
    }
  }
}

.rewards-button-container {
  display: flex;
  flex: 1;
  justify-content: space-between !important;

  .mx-3 {
    margin: 0 !important;
  }
}

.rewards-button-background {
  padding: 20px 15px;
}

.quantity-select-container {
  width: 200px !important;
}

.owl-carousel .owl-stage-outer {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

fgbcl-scorecard-carousel {
  .owl-carousel .owl-stage-outer {
    padding-left: 0 !important;
  }
}

fgbcl-number-input {
  .quantity-select-container {
    margin: auto !important;
  }
  .bid-amount-input {
    box-shadow: none !important;
    border: 1px solid $disabled-color !important;
    border-radius: 20px;
  }
}

fgbcl-marketplace-actions {
  .btn-marketplace-redeem {
    border-radius: 20px;
  }
}

@media (min-width: 576px) {
  .add-child-modal .modal-dialog {
    max-width: 625px !important;
  }
}

@media (min-width: 992px) {
  .rewards-confirm-divider.mb-lg-5 {
    margin-bottom: 0 !important;
  }
}

.add-child-modal .modal-content {
  overflow: visible;
}

.registration-payment-modal {
  .registration-payment-modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    background-color: $primary-color;
    color: #ffffff;

    .registration-payment-modal-title {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 18px;
    }

    .close-btn {
      cursor: pointer;
      margin-left: auto;
      font-family: 'Material Icons Outlined';
    }
  }

  .registration-payment-modal-info,
  .payment-info {
    display: flex;
    justify-content: center;
    margin: 30px 0px;

    p {
      display: flex;
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .registration-payment-modal-info {
    p {
      max-width: 400px;
    }
  }

  .payment-info {
    p {
      max-width: 310px;
    }

    .total-label {
      font-weight: 100;
    }

    .total-amount {
      margin-left: auto;
    }
  }

  .payment-container {
    display: flex;
    justify-content: center;
  }

  .payment-form {
    max-width: 310px;
  }
}

.modal-dialog {
  .modal-body {
    .row.my-5 {
      margin-bottom: 30px !important;
      margin-top: 40px !important;
    }
  }
}

#scorecard-actions-completed-number {
  margin-top: 0;
}

#scorecard-modal-name {
  font-family: $font-heading;
  text-transform: capitalize;
}

.invalid-token-modal {
  max-width: 700px;

  .modal-content {
    min-height: 280px;
    padding: 15px;
  }
}

/** Popup home screen shortcut */

.popup-add-to-home-screen-ios-modal-window {
  &.modal {
    height: fit-content;
    top: unset;
    bottom: 0px;
  }
}

.popup-add-to-home-screen-android-modal-window {
  &.modal {
    .modal-dialog {
      width: 290px;
      float: right;
    }
  }
}

.popup-add-to-home-screen-dialog {
  .modal-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

/** Games Page */
.games {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }

          .survey-container {
            &.bg-poll {
              @media (max-width: 991px) {
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

.score-predictor-owl {
  .owl-carousel {
    .owl-stage-outer {
      .owl-item {
        padding: 0px !important;
      }
    }
  }
}

/** Predictors */

fgb-survey-item-predictor {
  .survey-container {
    border: none !important;
  }
}

/** Scorecard Carousel */
.badge-carousel-div {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }
        }
      }
    }
  }
}

/** Terms & COnditions Page */
.terms-section-box {
  border: none;
  padding: 0 !important;
  .terms-section-content {
    h1 {
      font-size: 1rem;
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
}

fgbcl-language-selector {
  .language-heading {
    font-family: $font-default;
  }

  .btn-change-locale.bg-secondary {
    background-color: $primary-color !important;
  }

  .btn-change-locale {
    font-family: $font-default;
    font-weight: 100 !important;

    &.shadow {
      border: 1px solid rgba(0, 0, 0, 0.125);
      box-shadow: none !important;
    }
  }
}

fgb-mobile-nav-menu {
  .btn-change-locale:not(.bg-secondary) {
    background-color: #272727 !important;
    border-color: $primary-color;
    color: $white;
  }
}

/** Earn Points Page */
.earn-points-container {
  .earn-card-class {
    .card-header,
    .card-body {
      padding: 0.25rem 0.75rem !important;
    }

    .earn-sub-heading {
      font-size: 20px;
    }

    .card-header .dropdown-tog,
    .earn-section-description {
      display: none;
    }

    .card-body {
      .row {
        align-items: center;
      }
    }
  }
}

.notification-dropdown {
  border-radius: $border-radius;
  overflow: hidden;
}

fgbcl-scorecard-carousel {
  .scorecard-container {
    border-radius: 20px;
  }
  .bg-carousel {
    background-color: transparent !important;
  }
  .badge-carousel-subheading {
    color: $black;
  }
  .scorecard-featured-rewards-link {
    font-weight: 400;
  }
}

.bg-carousel {
  min-height: 280px !important;
}

.badge-carousel-div {
  padding-bottom: 0 !important;
}

fgbcl-badge-overview-list {
  .badge-subheading {
    color: $black;
    &:first-child {
      margin-top: 0 !important;
    }
  }
}

fgbcl-v2-scorecard-description-modal {
  .scorecard-description-modal {
    border-radius: 20px;

    .h-100.mb-3 {
      margin-bottom: 0 !important;
    }
  }
  .scorecard-modal-title {
    font-family: $font-heading;
  }
  .scorecard-description-modal-congratulation-title {
    color: $primary-color !important;
  }
  .scorecard-description-modal-prizebox {
    border: 1px solid $primary-color !important;
    border-radius: 20px !important;
  }
  .scorecard-description-modal-action-completed {
    font-size: 12px !important;
  }
  .scorecard-Milestone-Milestones-Value-model-v2 {
    font-size: 12px;
    color: $black;
  }
  .scorecard-description-modal-dash-line-top {
    font-size: 30px;
    color: $primary-color;
  }
  .scorecard-description-modal-prize-option-title {
    font-size: 12px;
  }
}

fgbcl-v2-scorecard-punchcard-view {
  .scorecard-punchcard-steps {
    font-weight: bold !important;
  }
  .scorecard-name {
    font-size: 16px;
  }
  .scorecard-punchcard-steps {
    margin-top: 2px;
  }
}

fgbcl-v2-milestones-progress-view {
  .scorecard-milestone-value {
    font-size: 12px;
    color: $black;
  }
  .scorecard-punchcard-steps {
    font-weight: bold !important;
  }
  .scorecard-name {
    font-size: 16px;
  }
}

fgbcl-v2-scorecard-number-view {
  .counter-number-scorecard {
    font-size: 30px !important;
  }
  .scorecard-name {
    font-size: 16px;
  }
}

fgbcl-marketplace-list-item,
fgbcl-marketplace-details,
fgbcl-lotto-details {
  .card {
    box-shadow: none !important;
  }
  .bg-mp-download.cta,
  .bg-mp-unique-code.cta,
  .bg-mp-event.cta,
  .bg-marketplace.cta,
  .bg-disabled.cta {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
  .bg-mp-download,
  .bg-mp-unique-code {
    background-color: $primary-color !important;
  }
  .text-on-mp-unique-code {
    color: $white !important;
  }
}

fgbcl-auction-panel,
fgbcl-lottos-list-item {
  .card {
    box-shadow: none !important;
  }
  .bg-lotto.cta,
  .bg-auction.cta,
  .bg-disabled.cta {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }
}

fgbcl-lottos-list-item,
fgbcl-lotto-details {
  .lotto-button {
    border-radius: 20px;
  }
}

fgbcl-lotto-details {
  fgbcl-number-input {
    .errors {
      position: absolute;
    }
  }
}

.corner-number-entries .number-entries {
  font-size: 0.75rem !important;
}

fgbcl-confirm-modal-terms {
  .confirm-modal-image,
  .btn {
    border-radius: 20px;
  }
}

fgbcl-marketplace-purchase-confirmation,
fgbcl-confirm-modal-terms {
  hr.mx-5 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.wallet-redeem-carousel {
  .owl-theme {
    .owl-dots {
      .owl-dot {
        &.active,
        &:hover {
          span {
            background: $primary-color;
          }
        }
      }
    }
  }
}

.modal-content {
  border-radius: 20px !important;
}

fgbcl-marketplace-purchase-confirmation {
  .confirm-modal-image {
    border-radius: 20px !important;
  }
  .btn-purchase-cancel {
    border-radius: 20px;
  }
  .btn-purchase-confirm {
    border-radius: 20px;
  }
}

fgbcl-featured-rewards {
  .featured-rewards-show-all {
    font-weight: 400;
    .icon {
      padding-left: 0.25rem;
    }
  }
  .featured-rewards-heading {
    text-transform: uppercase;
  }
}

.form-check-input:checked {
  background-color: $primary-color !important;
}

fgbcl-campaigns-offers-list {
  .campaigns-offers-list {
    padding-left: 0;
  }
  .offer-items-header {
    text-transform: uppercase;
  }
}

fgbcl-campaigns-list-item {
  .redeem-card {
    box-shadow: none !important;
    border: 1px solid $disabled-color !important;
    border-radius: 15px !important;
  }
  .logo {
    border-radius: 20px !important;
  }
}

fgbcl-marketplace-purchased-list-item {
  .redeem-card {
    border-radius: 20px !important;
  }
  .purchase-list-image {
    box-shadow: none !important;
    border-radius: 20px !important;
  }
}

.faq-container {
  .card {
    box-shadow: none !important;
    border: 1px solid $disabled-color !important;
  }
  .card .card-body {
    box-shadow: none !important;
    border: none !important;
  }
}

fgbcl-contact-us {
  .contact-us-box {
    box-shadow: none !important;
    border-radius: 20px !important;
  }
  .contact-us-dropdown,
  .contact-us-input-group,
  .contact-us-send-button {
    border-radius: 20px !important;
  }
}

fgbcl-announcement-content {
  .announcements-container {
    border-radius: 20px !important;
  }
  .announcements-main-title {
    text-transform: uppercase;
  }
  .announcements-title {
    font-family: $font-default;
    font-weight: bold !important;
  }
}

.survey-container {
  box-shadow: none !important;
  border: 1px solid $disabled-color;
  border-radius: 20px !important;
}

.image-container {
  box-shadow: none !important;
}

fgb-survey-item-quiz,
fgb-survey-item-poll {
  .survey-header {
    box-shadow: none !important;
  }
}

fgbcl-earn-points-card {
  .earn-card-points-value {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }

  .earn-card-display {
    font-size: 12px !important;
  }
}

fgbcl-rewards-wallet-redeemed-list {
  .col-12:first-of-type {
    .loyalty-item-container-redeemed,
    .border {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  }

  .col-12:last-of-type {
    .loyalty-item-container-redeemed,
    .border {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.loyalty-item-container-redeemed {
  background-color: #ffffff;
}

.earn-points-container .earn-card-class {
  background-color: transparent;
}

.corner-icon-container,
fgbcl-loyalty-icon.corner-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  fgbcl-loyalty-icon,
  .icon {
    position: relative !important;
    display: flex;
    top: 0 !important;
    left: 0 !important;
  }
}

fgbcl-terms-and-conditions {
  * {
    background-color: transparent !important;
  }
}

.round {
  border-radius: 25px !important;
}

fgbcl-marketplace-wallet-details {
  .wallet-back-button {
    color: $primary-color;

    .material-icons.pr-2 {
      padding-right: 3px !important;
    }

    span.font-size-14 {
      line-height: 16px !important;
    }
  }
  .border {
    border-radius: 20px;
  }
  .loyalty-details-image {
    box-shadow: none !important;
    border-radius: 20px !important;
  }

  @media screen and (max-width: 991px) {
    .container > div.mt-3 {
      margin-top: 0 !important;
    }
  }
}

fgbcl-card-link-earn-steps {
  .pl-3.pr-lg-2 {
    margin-top: auto;
    margin-bottom: auto;
  }
}

fgbcl-badge-list-item {
  .badge-container {
    border-radius: 20px;
  }
}

fgbcl-earn-points {
  .earn-sub-heading {
    font-size: 20px;
    text-transform: uppercase;
  }
}

fgbcl-earn-points-card {
  .earn-card-body {
    border-radius: 20px;
  }
}

fgbcl-faq-section {
  .card {
    border-radius: 20px !important;
  }
}

fgbcl-date-range-picker {
  .date-range-picker .form-control,
  .btn {
    border-radius: 20px;
  }
}

@media screen and (max-width: 991px) {
  fgb-transaction-history {
    .to-date-text {
      margin-top: 10px !important;
    }

    .search-button {
      margin-top: 20px !important;
    }
  }

  .rewards-button-container {
    flex-direction: column;
  }

  fgbcl-scorecard-carousel {
    .badge-carousel-div {
      padding-bottom: 0 !important;
    }
  }
}

.number-input-decrement-button,
.number-input-increment-button {
  border: none !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span,
.scorecard-description-modal .scorecard-actions-completed,
.btn-marketplace-redeem,
.lotto-button,
.marketplace-wallet-download-btn {
  background-color: $primary-color !important;
}

.scorecard-description-modal-prizebox.border-secondary,
.btn-marketplace-redeem,
.lotto-button,
.marketplace-wallet-download-btn {
  border-color: $primary-color !important;
}

.btn-rewards-redeem {
  background-color: $primary-color !important;
}

.rewards-item-details,
.text-rewards-success,
.rewards-item-panel-value,
.text-rewards-success .text-success {
  color: $primary-color !important;
}

.form-floating {
  .form-select,
  label {
    font-size: 14px !important;
  }
}

.btn-purchase-confirm,
.confirm-modal .btn-confirm {
  background-color: $primary-color !important;
}

.btn-rewards-redeem {
  border-color: inherit !important;
}

.wallet-marketplace-item-name {
  font-size: 12px;
}

.scorecard-description-modal .progress #scorecard-milestone-bar {
  background-color: $primary-color !important;
}

#scorecard-progress-bar,
.circle-filled {
  background-color: $primary-color !important;
}

fgb-survey-item {
  .countdown-timer {
    font-size: 14px !important;
  }
}

fgb-survey-item-answer-completed-poll-item {
  .answer-text-landscape {
    font-size: 12px !important;
  }
}

.offcanvas-attribute .offcanvas-attribute-btn-active {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.barcode-cls {
  border-radius: 18px;
  overflow: hidden;
  height: 124px;
  width: 123px;
}

.scorecard-featured-link,
.featured-rewards-text,
.link-title {
  font-size: 12px !important;
}

// CSS hacks

fgbcl-marketplace-wallet-details {
  :has(.marketplace-wallet-download-btn) {
    .wallet-divider {
      display: none !important;
    }

    .wallet-info-box a {
      margin: 10px !important;
    }
  }
}

:has(.marketplace-wallet-download-btn) {
  .wallet-info-box {
    margin-top: 4px !important;
  }
}

:has(#purchase-now-button:disabled) {
  #amountInput {
    pointer-events: none;
    opacity: 0.3;
  }
}

:has(.status-container) {
  .rewards-details-desc-terms {
    margin-top: 0 !important;
  }
}

.btn.language-dropdown-btn:focus,
.btn.language-dropdown-btn:active,
.btn.language-dropdown-btn:hover,
.language-item .language-code {
  color: $black !important;
}

.language-item.selected .language-code {
  color: $primary-color !important;
}

fgbcl-registration-terms-and-conditions {
  .terms-section-box {
    padding: 0 !important;
  }
}

fgb-registration-layout-page {
  h5 {
    font-family: $font-heading !important;
    font-size: 22px !important;
  }
}

fgb-fantasy-games-page {
  ngb-accordion .collapse.show .content {
    max-height: 1200px;
    margin-top: -2px;
  }

  .games-rules-container {
    .accordion-item:first-of-type,
    .accordion-item:first-of-type .accordion-header .accordion-button {
      border-top-left-radius: 16px !important;
      border-top-right-radius: 16px !important;
    }

    .accordion-item:last-of-type,
    .accordion-item:last-of-type .accordion-header .accordion-button.collapsed {
      border-bottom-right-radius: 16px !important;
      border-bottom-left-radius: 16px !important;
    }
  }

  .accordion-item,
  .accordion-button {
    background-color: $white !important;
    font-family: $font-default;
    font-size: 0.875rem;
    font-weight: bold;
  }

  .accordion-button:not(.collapsed) {
    background-color: $primary-color !important;
    color: $white !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
}

.accordion-button::after {
  align-self: flex-start;
}

@import 'bootstrap/scss/bootstrap';

/* ON COLOURS */

@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;
    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }
    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}
