@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Voucher component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming
*/
@mixin voucher-style($theme: theme('voucher', var.$primary-color, #f1f1f2, #ffffff, var.$primary-color), $variables...) {
  fgb-vouchers {
    $var: keywords($variables);
    $primary-color: map-get($theme, 'primary');
    $secondary-color: map-get($theme, 'secondary');
    $tertiary-color: map-get($theme, 'tertiary');
    $accent-color: map-get($theme, 'accent');
    $background-color: $tertiary-color;

    .card {
      background-color: get-color-by-background($background-color, var.$black, var.$white) !important;
      border-color: get-color-by-background($background-color, $accent-color, rgba(0, 0, 0, 0.125)) !important;
    }

    h4 {
      color: get-color-by-background($background-color, #ffffff, $primary-color);
    }

    .code-input {
      color: get-color-by-background($background-color) !important;
      background-color: get-color-by-background($background-color, var.$black, #f1f1f2) !important;
      border: 1px solid get-color-by-background($secondary-color, $primary-color, #ffffff) !important;
      border-right: none;
    }

    small.text-danger {
      color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
    }

    input::placeholder {
      font-size: 14px;
      color: get-color-by-background($background-color, #ffffff, #707070) !important;
    }

    small.text-success {
      color: get-color-by-background($background-color, #ffffff, var.$success-color) !important;
    }

    @content;
  }
}
