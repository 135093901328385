@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Wallet redeem list component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin wallet-redeemed-list-style(
  $theme: theme('wallet-redeemed-list', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)
) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgbcl-rewards-wallet-redeemed-list {
    .loyalty-item-container-redeemed {
      background-color: unset;
    }

    .lotto-wallet-claimed,
    .marketplace-wallet-claimed {
      color: get-color-by-background($background-color, #ffffff, var.$error-color);
    }

    .wallet-container {
      border-color: get-color-by-background($background-color, $accent-color, #dedddd) !important;
      background-color: $background-color;
    }

    @content;
  }

  .back-button {
    color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
  }

  .no-claimed-rewards {
    border-radius: var.$border-radius;
    color: get-color-by-background($background-color);
    background-color: get-color-by-background($background-color, $tertiary-color, #ffffff);
    border-color: get-color-by-background($background-color, $accent-color, #dedddd) !important;
  }
}
