@use 'club-overrides' as var;
@use 'partials' as *;

/*
    Feature rewards component theming
    @param $theme - the theme to be used for individual component
    @param $variables - additional or custom variables to be used for theming ex. $border-color
*/
@mixin wallet-details-style($theme: theme('wallet-details', var.$primary-color, #f3f3f3, #ffffff, var.$primary-color)) {
  $primary-color: map-get($theme, 'primary');
  $secondary-color: map-get($theme, 'secondary');
  $tertiary-color: map-get($theme, 'tertiary');
  $accent-color: map-get($theme, 'accent');
  $background-color: $tertiary-color;

  fgbcl-marketplace-wallet-details {
    .loyalty-details .border.d-block {
      background-color: $background-color;
      padding-top: 4px;
      border-color: $primary-color !important;
    }

    .loyalty-details .border.my-3 {
      background-color: $background-color;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border: 1px solid get-color-by-background($secondary-color, $accent-color, rgba(0, 0, 0, 0.125)) !important;

      .wallet-unique-code-item {
        padding-top: 15px !important;
        padding-bottom: 0 !important;
        color: get-color-by-background($background-color, $accent-color, $primary-color) !important;
      }

      .marketplace-unique-code-wallet {
        padding-bottom: 15px !important;
      }
    }

    .my-2.border-0 {
      padding: 15px;
      background-color: $background-color;
      border-radius: 25px;
      margin-top: 20px !important;
      border: 1px solid get-color-by-background($secondary-color, $accent-color, rgba(0, 0, 0, 0.125)) !important;
    }

    .font-size-12.text-danger {
      color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
    }

    .wallet-back-button {
      color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
    }

    hr.border-top {
      border-color: get-color-by-background($secondary-color, #272727, rgba(0, 0, 0, 0.125)) !important;
    }
  }

  fgbcl-lottos-wallet-details {
    .lotto-wallet-item-claimed {
      color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
    }
    .wallet-back-button {
      color: get-color-by-background($background-color, #ffffff, var.$error-color) !important;
    }

    hr.border-top {
      border-color: get-color-by-background($secondary-color, #272727, rgba(0, 0, 0, 0.125)) !important;
    }
  }

  .lotto-wallet-item-claimed {
    color: #ffffff;
  }
}
